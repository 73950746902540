import { createWebHistory, createRouter } from 'vue-router';
import routes from './routes';

// let prefix = '/admin/';

// let routesList = [];

// function initComponents(url){
//   switch (url) {
//     case 'programs':
//       return Programs;
  
//     default:
//       break;
//   }
//   return null;
// }
// for (let i = 0; i < menuItems.menu.length; i++) {
//   let element = menuItems.menu[i];
//   let route = {};
//   if(!element.navheader){
//     route.component = routes[element.url];
//   }
//   route.path = prefix + element.url
//   route.name = prefix + element.name
//   routesList.push(route);
// }

  const router = createRouter({
    history: createWebHistory(),
    routes: routes,
  });
export default router;