<template>
    <div class="landing">
        <div class="top">
            <div class="logo"><img ></div>
            <div class="button-border">Создать приложение  ↗</div>
        </div>
        <div class="main-info">
            <div class="main-info-left">
                <h1 class="main-title">Попробуйте демо-приложение!</h1>
                <div class="main-info-buttons">
                    <div class="button-white">Скачать на iPhone</div>
                    <div class="button-white">Скачать на Android</div>
                </div>
            </div>
                <div class="main-info-img">
                    <img src="../assets/imgs/phone.webp" />
                </div>
        </div>
        <h1 class="block-title">Выбирать и заказывать — одно удовольствие!</h1>
        <div class="double-screenshots">
            <div class="double-screenshot">
                <img src="../assets/imgs/feature_01.webp"/>
                <div class="double-screenshot-text">Собрать заказ стало еще проще: сделали меню удобнее, добавили составы и калорийность</div>
            </div>
            <div class="double-screenshot">
                <img src="../assets/imgs/feature_02.webp"/>
                <div class="double-screenshot-text">Вжух, какое быстрое оформление заказа! Заполним адрес сами или подскажем ближайший ресторан</div>
            </div>
            <div class="double-screenshot">
                <img src="../assets/imgs/feature_03.webp"/>
                <div class="double-screenshot-text">Не хочешь выходить из машины или подтверждать заказ по телефону? Просто поставь две галочки!</div>
            </div>
            <div class="double-screenshot">
                <img src="../assets/imgs/feature_04.webp"/>
                <div class="double-screenshot-text">Распоряжаться бонусами и контролировать баланс теперь можно прямо в приложении</div>
            </div>
        </div>
        <h1 class="block-title">Кое-что еще, что можно сделать в новом приложении</h1>
        <div class="screenshots">
            <div class="screenshot">
                <img src="../assets/imgs/Feature_next_01.webp"/>
                <div class="screenshot-text">Разложить общий заказ по разным пакетам</div>
            </div>
            <div class="screenshot">
                <img src="../assets/imgs/Feature_next_02.webp"/>
                <div class="screenshot-text">Узнать статус заказа</div>
            </div>
            <div class="screenshot">
                <img src="../assets/imgs/Feature_next_03.webp"/>
                <div class="screenshot-text">Забронировать столик в ресторане</div>
            </div>
        </div>
        <div class="block-qr">
            <div class="block-qr-left">
                <h1 class="block-qr-title">Скачайте демо-приложение прямо сейчас!</h1>
                <div class="main-info-buttons">
                    <div class="button-white">Скачать на iPhone</div>
                    <div class="button-white">Скачать на Android</div>
                </div>
            </div>
            <div class="block-qr-img"><img src="../assets/imgs/download.png"/></div>
        </div>
        <div class="button-border" :style="{ width: '1200px', margin: 'auto', padding: '26px 0px' }">Создать приложение</div>
    </div>
</template>

<script>
//import { ref, computed } from 'vue'
export default {
  name: 'App',
  setup(){
  }
}
</script>

<style>
@font-face {
  font-family: 'NamuPro';
  src: local('NamuPro'),
    url('~@/assets/fonts/NAMU-Pro.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
.landing{
    height: 100%;
    background: #1c1c1c;
    padding-bottom: 35px;
}
.top {
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1200px;

    padding-bottom: 35px;

}
.button-border {
    color: white;
    color: #ffffff;
    font-size: 18px;
    font-family: 'NamuPro',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 600;
    border-width: 1px;
    border-radius: 30px;
    background-position: center center;
    border-color: #393939;
    border-style: solid;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    padding: 10px 33px;
    margin-top: 40px;
    cursor: pointer;
    text-align: center;
}
.button-border:hover {
    background-color: #2c2c2c;
    background-image: none;
    border-color: #2c2c2c;
}
.main-info-buttons {
    display: flex;
}

.button-white {
    background: white;
    color: #1c1c1c;
    font-size: 18px;
    font-family: 'NamuPro',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 600;
    border-width: 1px;
    border-radius: 47px;
    background-position: center center;
    border-color: #393939;
    border-style: solid;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    padding: 21px 33px;
    margin-top: 40px;
    cursor: pointer;
    margin-right: 14px;
}
.button-white:hover {
    background-color: #d2d2d2;
    background-image: none;
}
.main-info {
    display: flex;
    width: 1200px;
    margin: auto;
    margin-top: 100px;
}
h1.main-title {
    color: #ffffff;
    font-size: 53px;
    font-family: 'NamuPro',Arial,sans-serif;
    line-height: 1.2;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}
.main-info-buttons {
    display: flex;
}
.button-white {
    background: white;
    color: #1c1c1c;
    font-size: 18px;
    font-family: 'NamuPro',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 600;
    border-width: 1px;
    border-radius: 47px;
    background-position: center center;
    border-color: #393939;
    border-style: solid;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    padding: 21px 33px;
    margin-top: 40px;
    cursor: pointer;
    margin-right: 14px;
}
h1.block-title {
    color: #ffffff;
    font-size: 56px;
    font-family: 'NamuPro',Arial,sans-serif;
    line-height: 1.2;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    width: 1000px;
    margin: auto;
    text-align: center;
    margin-top: 250px;
    margin-bottom: 100px;
}
.screenshots {
    display: flex;
    justify-content: center;
}
.screenshot {
    width: 250px;
    margin: 0px 30px;
    text-align: center;
}
.screenshot-text {
    color: #d0d0d0;
    font-size: 20px;
    font-family: 'NamuPro',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    text-align: center;
}
.double-screenshots {
    display: flex;
    overflow-x: scroll;
    margin: auto;
}
.double-screenshot {
    width: 400px;
    margin: 0px 30px;
    text-align: center;
}
.double-screenshots::-webkit-scrollbar {
    width: 7px;
    background-color: #f9f9fd00;
}


.double-screenshot-text {
    color: #d0d0d0;
    font-size: 20px;
    font-family: 'NamuPro',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    text-align: center;
}
.block-qr {
    display: flex;
    width: 1200px;
    margin: auto;
    padding: 65px;
    background: #2c2c2c;
    border-radius: 64px;
    margin-top: 90px;
    margin-bottom: 35px;
}
.block-qr-left {
    width: 80%;
}

.block-qr-title {
    color: #ffffff;
    font-size: 53px;
    font-family: 'NamuPro',Arial,sans-serif;
    line-height: 1.2;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    width: 100%;
    margin: auto;
    text-align: center;
}
.block-qr-left .main-info-buttons {
    display: flex;
    /* text-align: center; */
    justify-content: center;
}
.block-qr-img img {
    border-radius: 20px;
}
</style>
