<template>
    <div>{{ id }}</div>
</template>
<script>
import { useStore } from 'vuex'
export default ({
    setup(){
        const store = useStore()
        let id = store.state.appId
        return {
            id
        }
    }
});
</script>
<style>
</style> 