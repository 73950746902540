<template>
    <div class="apps-wrapper">
      <h1 class="apps-title">Ваши приложения</h1>
      <div class="apps">
        <slot/>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Apps'
}
</script>

<style>
.apps {
    display: flex;
    justify-content: center;
}
.apps-wrapper {
    padding-top: 10%;
    height: 100%;
    background: #15acbf;
}
.apps-title {
    text-align: center;
    color: white;
    margin-bottom: 40px;
}
</style>
