<template>
    <slot/>
</template>

<script>
export default {
  name: 'Landing'
}
</script>

<style>
</style>
