<template>
    
    <div class="apps-wrapper">
        <h1 class="apps-title">Новое приложение</h1>
        <a-layout-content :style="{ padding: '50px', margin: '50px', marginTop: '64px', background: 'white' }">
            <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-item label="Аватар приложение">
                    <AvatarInput/>
                </a-form-item>
                <a-form-item label="Название">
                    <a-input  />
                </a-form-item>
                <a-form-item label="Описание">
                    <a-textarea :rows="4" placeholder="У вас вы можете..." :maxlength="6" />
                </a-form-item>
                <a-row>
                    <a-col :span="24" style="text-align: right">
                        <a-button v-if="!loading" type="primary" html-type="submit">Создать</a-button>
                        <a-button v-else type="primary" loading>Создание</a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-layout-content>
    </div>
</template>
<script>
// import { usePagination } from 'vue-request';
import { /* computed ,*/ defineComponent } from 'vue';
// import axios from 'axios';
// import { useStore } from 'vuex'

import AvatarInput from '../components/AvatarInput'

export default defineComponent({
  components: {
      AvatarInput
  },
  setup() {
  },

});
</script>
<style>
</style>