import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import Toaster from '@meforma/vue-toaster';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import router from './Router/Index';
import Store from './Store/index'

let app = createApp(App).use(Antd).use(Toaster).use(router).use(Store).mount('#app')


export default app;