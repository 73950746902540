<template>
        <slot/>
</template>

<script>
export default {
  name: 'Empty'
}
</script>

<style>
</style>
