<template>
  <a-table :dataSource="dataSource" :columns="columns" />
</template>
<script>
  export default {
    setup() {
      return {
        dataSource: [
            {
                name: 'Борщ',
                description: 'Традиционное русское блюдо',
                price: '150'
            },
            {
                name: 'Рамен',
                description: 'Традиционное японское блюдо',
                price: '300'
            },
            {
                name: 'Хачипури',
                description: 'Традиционное грузинское блюдо',
                price: '200'
            },
        ],

        columns: [
          {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
          },
          {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
          },
        ],
      };
    },
  };
</script>