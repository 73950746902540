<template>
  <router-link :to="'/admin/apps'">приложения</router-link>
  <router-link :to="'/admin/login'">login</router-link>

  <transition name="page-fade" appear mode="out-in">
    <component v-bind:is="layout">
        <router-view />
    </component>
  </transition>
</template>

<script>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import Admin from './layouts/Admin'
import Landing from './layouts/Landing'
import Auth from './layouts/Auth'
import Apps from './layouts/Apps'
import Empty from './layouts/Empty'

export default {
  name: 'App',
  setup(){
    const router = useRouter()
    const store = useStore()
    const componets = {
      Admin,
      Landing,
      Auth,
      Apps,
      Empty
    }
    const route = useRoute()
    const layout = computed(()=>{
      return componets[route.meta.layout]
    })

    store.watch((state, getters)=>getters.doneAppId, ()=>{
      console.log('handle change app id')
      router.push('/admin/home')
    })
    return {
      layout
    }
  }
}
</script>

<style>
div#app {
    height: 100%;
}
.page-fade-enter-from{
  opacity: 0;
}
.page-fade-enter-to{
  opacity: 1;
}
.page-fade-leave-active, .page-fade-enter-active{
  transition: opacity 500ms;
}
.page-fade-leave-to{
  opacity: 0;
}
.page-fade-leave{
  opacity: 1;
}
html{
  height: 100%;
}
</style>
