<template>
  <a-table :dataSource="dataSource" :columns="columns" />
</template>
<script>
  export default {
    setup() {
      return {
        dataSource: [
          {
          "id": 36,
          "name": "dmitry",
          "surname": "done",
          "rating": 0.0,
          "balance": 0.0,
          "phone": "+79084434076",
          "email": "aaaa@gmail.com",
          "isEmailVerified": false,
          "avatar": null,
          "unreadFromAdminMessagesCount": 0,
          "unreadToAdminMessagesCount": 0,
          "lastAdminMessage": null,
          "isEmailUnsubscribed": false,
          "isCustomer": true,
          "isExecutor": false,
          "isAdmin": false,
          "city": "vladivostok",
          "cityTitle": "Владивосток",
          "cityFullTitle": "г. Владивосток, Приморский край",
          "creationDate": "2022-01-20T19:36:04.000001+10:00",
          "birthDate": "1989-01-20T00:00:00.000001+10:00",
          "reviewCount": 0,
          "about": "",
          "isVerified": false,
          "isVerifiedCustomer": false,
          "isVerifiedExecutor": false,
          "createdOrders": 0,
          "compliteOrders": 0,
          "customerTotalCount": 0,
          "executorTotalCount": 0,
          "customerTotalAmount": 0,
          "executorTotalAmount": 0,
          "lastActivity": "2022-01-20T19:36:04.000001+10:00",
          "isBanned": false,
          "addresses": [],
          "session": null,
          "adminComment": "",
          "unreadNotifications": 1,
          "registrationDevice": "ios",
          "registrationAppType": "aiuta"
        },
        {
          "id": 35,
          "name": "Дмитрий",
          "surname": "Кукушек",
          "rating": 0.0,
          "balance": 0.0,
          "phone": "+79875375857",
          "email": null,
          "isEmailVerified": false,
          "avatar": null,
          "unreadFromAdminMessagesCount": 0,
          "unreadToAdminMessagesCount": 0,
          "lastAdminMessage": null,
          "isEmailUnsubscribed": false,
          "isCustomer": true,
          "isExecutor": false,
          "isAdmin": false,
          "city": "vladivostok",
          "cityTitle": "Владивосток",
          "cityFullTitle": "г. Владивосток, Приморский край",
          "creationDate": "2022-01-14T15:27:09.000001+10:00",
          "birthDate": "2004-01-14T14:48:59.000001+10:00",
          "reviewCount": 0,
          "about": "",
          "isVerified": false,
          "isVerifiedCustomer": false,
          "isVerifiedExecutor": false,
          "createdOrders": 0,
          "compliteOrders": 0,
          "customerTotalCount": 0,
          "executorTotalCount": 0,
          "customerTotalAmount": 0,
          "executorTotalAmount": 0,
          "lastActivity": "2022-01-14T15:27:58.000001+10:00",
          "isBanned": false,
          "addresses": [],
          "session": null,
          "adminComment": "",
          "unreadNotifications": 1,
          "registrationDevice": "ios",
          "registrationAppType": "guskaras"
        },
        ],

        columns: [
          {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
          },
          {
            title: 'Почта',
            dataIndex: 'email',
            key: 'email',
          },
        ],
      };
    },
  };
</script>