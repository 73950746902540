<template>
  <a-upload
    v-model:file-list="files"
    name="avatar"
    list-type="picture"
    :maxCount="1"
    :action="'https://aiuta.ams3.digitaloceanspaces.com/uploadimage'"
  >
    <a-button>
        <upload-outlined></upload-outlined>
        upload
    </a-button>
  </a-upload>
</template>
<script>
import { UploadOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref } from 'vue';
import { requestDirectly } from '../api.js'

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

export default defineComponent({
  components: {
      UploadOutlined
  },

  setup() {
    const files = ref([]);
    const loading = ref(false);
    const imageUrl = ref('');
    const request = (data)=>{
        loading.value = true;
        const formData = new FormData();
        formData.append('image', data.file);

        requestDirectly({ uri: '/uploadimage', params: formData, local: 1}).then((response) => {
            // $('.user-avatar').on('load', ()=> {
            //     this.loadingAvatar = false;
            //     this.$emit('loading', this.loadingAvatar);
            // });
            // this.avatarId = response.data.id;
            // console.log(response.data.preview);
            // this.$emit('changeAvatar', { id: this.avatarId, image: 'https://aiuta.ams3.digitaloceanspaces.com/' + response.data.preview});
            imageUrl.value = 'https://aiuta.ams3.digitaloceanspaces.com/' + response.data.preview;
            loading.value = false;
        }).catch(error => {
            // message.error('Ошибка запроса!');
            // loading.value = false;
            console.log(error)
            imageUrl.value = 'https://sovcominvest.ru/uploads/photo/11237/image/2b22c3f5c79f188acfd76e225c87275b.jpg';
            loading.value = false;
        });
    }
    return {
      files,
      loading,
      imageUrl,
      request
    };
  },

});
</script>
<style>
.upload-list-inline :deep(.ant-upload-list-item) {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline [class*='-upload-list-rtl'] :deep(.ant-upload-list-item) {
  float: right;
}
</style>