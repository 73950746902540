//let prefix = '/admin/';
import HomeLanding from '../pages/HomeLanding.vue'
import Login from '../pages/Login.vue'
import Register from '../pages/Register.vue'
import AppsList from '../pages/AppsList.vue'
import AppsCreate from '../pages/AppsCreate.vue'
import AdminHome from '../pages/AdminHome.vue'
import AdminCustomers from '../pages/AdminCustomers.vue'
import AdminDishes from '../pages/AdminDishes.vue'
import AdminOrders from '../pages/AdminOrders.vue'

export default [
    //LANDING
    { path: '/', component: HomeLanding, meta: { layout: 'Landing' } },
    //ADMIN
    { path: '/admin/login', component: Login, meta: { layout: 'Auth' } },
    { path: '/admin/reg', component: Register, meta: { layout: 'Auth' } },
    { path: '/admin/apps', component: AppsList, meta: { layout: 'Apps' } },
    { path: '/admin/newapp', component: AppsCreate, meta: { layout: 'Empty' } },
    { path: '/admin/home', component: AdminHome, meta: { layout: 'Admin' } },
    { path: '/admin/customers', component: AdminCustomers, meta: { layout: 'Admin' } },
    { path: '/admin/dishes', component: AdminDishes, meta: { layout: 'Admin' } },
    { path: '/admin/orders', component: AdminOrders, meta: { layout: 'Admin' } },

]