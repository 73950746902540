<template>
  <a-card hoverable style="width: 300px" @click="selectApp(1)">
    <template #cover>
      <img
        alt="example"
        src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
      />
    </template>
    <template #actions>
    </template>
    <a-card-meta title="Card title" description="This is the description">
    </a-card-meta>
  </a-card>
  <a-card hoverable style="width: 300px" @click="goNewApp">
    <template #cover>
      <div class="create-app-plus">+</div>
    </template>
    <template #actions>
    </template>
    <a-card-meta title="Создать приложение">
    </a-card-meta>
  </a-card>
</template>
<script>
import { usePagination } from 'vue-request';
import { computed, defineComponent } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const columns = [{
  title: 'ID',
  dataIndex: 'id',
  width: '20%',
},{
  title: 'Имя',
  dataIndex: 'name',
  width: '80%',
},];

const queryData = params => {
  return axios.get('https://randomuser.me/api?noinfo', {
    params,
  });
};

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
    } = usePagination(queryData, {
      formatResult: res => res.data.results,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'results',
      },
    });
    const pagination = computed(() => ({
      total: 200,
      current: current.value,
      pageSize: pageSize.value,
    }));

    const handleTableChange = (pag, filters, sorter) => {
      run({
        results: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    };

    const selectApp = (id)=>{
      console.log('click app')
      store.commit('CHANGE_APP_ID', id)
    }
    const goNewApp = ()=>{
      router.push('/admin/newapp')
    }
    return {
      dataSource,
      pagination,
      loading,
      columns,
      handleTableChange,
      selectApp,
      goNewApp
    };
  },

});
</script>
<style>
.ant-card{
  margin: 0 20px;
}
.ant-card-cover {
    height: 180px;
}
.create-app-plus {
    height: 100%;
    text-align: center;
    line-height: 180px;
    font-size: 60px;
    color: #03a9f4;
}
.ant-card-hoverable:hover {
    border-color: #ffffff;
    box-shadow: 0 2px 14px rgb(0 0 0 / 43%);
}
</style>