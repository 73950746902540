import axios from 'axios';
import { ApiDomain, ApiPort } from './config'
import app from './main.js'
const VueCliDEv = true
const UrlServer = 'http://funfit_site/'
const UrlAPI = 'https://stage.talanco.ru'

async function request({ uri, method, params, is_cookie, headers}){
    console.log(uri, method, params, is_cookie, headers);
    method = !method ? 'post' : method;
    headers = !headers ? {} : headers;
    let error = false;
    let response = await axios({
        method: 'post',
        url: (VueCliDEv ? UrlServer : '')  + '/admin/api',
        data: {
            uri,
            params,
            method
        },
    }).catch((e)=>{
        console.log(e.toJSON())
        error = {};
        error['description'] = e.response.data.description;
    })
    if(error){
        throw error;
    }else{
        if(String(response.data.content.httpCode).split('')[0] == 4){
            app.$toast.error(response.data.content.description + ': ' + response.data.content.extra);
            return false;
        }
        return response
    }
}
function requestCancellable({ uri, method, params, is_cookie, headers}){
    console.log(uri, method, params, is_cookie, headers);
    method = !method ? 'post' : method;
    headers = !headers ? {} : headers;
    let error = false;
    let source = axios.CancelToken.source();
    let req = axios({
        method: 'post',
        url: '/admin/api',
        data: {
            uri,
            params,
            method
        },
        cancelToken: source.token
    }).catch((e)=>{
        console.log(e);
        error = {};
        error['description'] = e.response.data.description;
    })
    if(error){
        throw error;
    }else{
        return { req, cancel: source.cancel };
    }
}
async function requestDirectly({ uri, method, params,  local}){
    method = !method ? 'post' : method;
    console.log(uri, ApiDomain)
    let response = await axios({
        method: method,
        url: (VueCliDEv ? UrlAPI : (!local ? ApiDomain + ( ApiPort ? (':' + ApiPort) : '' ) : '' ) ) + uri,
        data: params
    }).catch((e)=>{
        console.log(e.toJSON())
        app.$toast.error(e.response.data.description + ': ' + e.response.data.extra);
    })
    if(!response)
        return
    if(String(response.data.httpCode).split('')[0] == 4){
        app.$toast.error(response.data.content.description + ': ' + response.data.content.extra);
        return false;
    }
    return response
}
export {request, requestDirectly, requestCancellable} ;