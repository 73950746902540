<template>
    <div class="wrapper-auth">
        <div class="block-auth">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Auth'
}
</script>

<style>
.block-auth {
    width: 33%;
    margin: auto;
    margin-top: 30vh;
    border-radius: 7px;
    background: white;
    border: 1px solid #ccc;
    padding: 50px 90px 50px 0px;
}
</style>
