let ApiDomain = window.config_API //'https://steel.aiuta.ru';
let ApiPort = window.config_API_PORT
let ShortApiDomain = window.config_API_DOMAIN //'steel.aiuta.ru';
let ImgDomain ='https://aiuta.ams3.digitaloceanspaces.com/';

export {
    ApiDomain,
    ApiPort,
    ShortApiDomain,
    ImgDomain
}