<template>
  <a-table :dataSource="dataSource" :columns="columns" />
</template>
<script>
  export default {
    setup() {
      return {
        dataSource: [
            {
                id: '0',
                price: '100',
                phoneCustomer: '+79644507014',
                dueDate: '2021-12-23'
            }
        ],

        columns: [
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: 'Телефон',
            dataIndex: 'phoneCustomer',
            key: 'phoneCustomer',
          },
          {
            title: 'Дата доставки',
            dataIndex: 'dueDate',
            key: 'dueDate',
          },
          {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
          },
        ],
      };
    },
  };
</script>