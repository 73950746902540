<template>
<div class="wrapper-reg">
<a-form
    :model="formState"
    :label-col="{ span: 8 }"
    :wrapper-col="{ span: 16 }"
    ref="formRef"
    autocomplete="off"
    :rules="rules"
    @finish="handleFinish"
    @validate="handleValidate"
    @finishFailed="handleFinishFailed"
  >
    <a-form-item label="Логин" name="login" :rules="[{ required: true, message: 'Введите логин.' }]">
      <a-input v-model:value="formState.login" />
    </a-form-item>
    <a-form-item label="Имя" name="name" :rules="[{ required: true, message: 'Введите имя.' }]">
      <a-input v-model:value="formState.name" />
    </a-form-item>
    <a-form-item label="Фамилия" name="surname" :rules="[{ required: true, message: 'Введите фамилию.' }]">
      <a-input v-model:value="formState.surname" />
    </a-form-item>
    <a-form-item label="E-mail" name="email" :rules="[{ required: true, message: 'Введите E-mail.' }]">
      <a-input v-model:value="formState.email" />
    </a-form-item>
    <a-form-item has-feedback label="Пароль" name="password">
        <a-input v-model:value="formState.password" type="password" autocomplete="off" />
    </a-form-item>
    <a-form-item has-feedback label="Подтвердите пароль" name="checkPass">
        <a-input v-model:value="formState.checkPass" type="password" autocomplete="off" />
    </a-form-item>
    <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
      <a-button v-if="!loading" type="primary" html-type="submit">Зарегистрироваться</a-button>
      <a-button v-else type="primary" loading>Вход</a-button>
    </a-form-item>
  </a-form>
</div>
</template>
<script>
import { defineComponent, reactive, ref } from 'vue';
import { requestDirectly } from '../api.js';
import { useRouter } from 'vue-router'
import Cookies from 'js-cookie';

export default defineComponent({
  setup() {
    const router = useRouter()
    const formRef = ref();
    const formState = reactive({
      login: '',
      name: '',
      surname: '',
      email: '',
      password: '',
      checkPass: '',
    });

    let validatePass = async (_rule, value) => {
      if (value === '') {
        return Promise.reject('Please input the password');
      } else {
        if (formState.checkPass !== '') {
          formRef.value.validateFields('checkPass');
        }

        return Promise.resolve();
      }
    };

    let validatePass2 = async (_rule, value) => {
      if (value === '') {
        return Promise.reject('Please input the password again');
      } else if (value !== formState.password) {
        return Promise.reject("Two inputs don't match!");
      } else {
        return Promise.resolve();
      }
    };

    const rules = {
      password: [{
        required: true,
        validator: validatePass,
        trigger: 'change',
      }],
      checkPass: [{
        validator: validatePass2,
        trigger: 'change',
      }],
    };
    const layout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
    };

    const loading = ref(false)
    const handleFinish = values => {
      loading.value = true;
      requestDirectly({ uri: '/api/admin/signup', params: {
        login: formState.login,
        password: formState.password,
        cityName: 'vladivostok'
      }}).then(response => {
          Cookies.set('AiutaAdminAuth', response.headers['x-cookie'])
          router.push('/admin/programs')
          loading.value = false;
      }).catch((error) => {
          console.error(error)
          loading.value = false;
      });
      console.log('Success:', values);
      return false;
    };

    const handleFinishFailed = errors => {
      console.log(errors);
    };

    const resetForm = () => {
      formRef.value.resetFields();
    };

    const handleValidate = (...args) => {
      console.log(args);
    };

    return {
      formState,
      formRef,
      rules,
      layout,
      handleFinishFailed,
      handleFinish,
      resetForm,
      loading,
      handleValidate,
    };
  },

});
</script>