import { createStore } from 'vuex'
const store = createStore({
    state: {
        appId: null
    },
    getters: {
        doneAppId(state){
            return state.appId;
        }
    },
    mutations: {
        CHANGE_APP_ID(state, data){
            console.log('change app id')
            state.appId = data
        }
    }
})

export default store;