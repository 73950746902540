<template>
<a-form
    :model="formState"
    name="basic"
    :label-col="{ span: 8 }"
    :wrapper-col="{ span: 16 }"
    autocomplete="off"
    @finish="onFinish"
    @finishFailed="onFinishFailed"
  >
    <a-form-item
      label="Имя аккаунта"
      name="login"
      :rules="[{ required: true, message: 'Введите имя аккаунта!' }]"
    >
      <a-input v-model:value="formState.login" />
    </a-form-item>

    <a-form-item
      label="Пароль"
      name="password"
      :rules="[{ required: true, message: 'Введите пароль!' }]"
    >
      <a-input-password v-model:value="formState.password" />
    </a-form-item>

    <a-form-item name="remember" :wrapper-col="{ offset: 8, span: 16 }">
      <a-checkbox v-model:checked="formState.remember">Запомнить меня</a-checkbox>
    </a-form-item>

    <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
      <a-button v-if="!loading" type="primary" html-type="submit">Войти</a-button>
      <a-button v-else type="primary" loading>Вход</a-button>
      Или <router-link :to="'/admin/reg'">Зарегистрироваться</router-link>
    </a-form-item>
  </a-form>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import { requestDirectly } from '../api.js';
import { useRouter } from 'vue-router'
import Cookies from 'js-cookie';

export default defineComponent({
  setup() {
    const router = useRouter()
    const formState = reactive({
      login: '',
      password: '',
      remember: true
    });
    const loading = ref(false)
    const onFinish = values => {
      loading.value = true;
      requestDirectly({ uri: '/api/admin/auth', params: {
        login: formState.login,
        password: formState.password
      }}).then(response => {
          Cookies.set('AiutaAdminAuth', response.headers['x-cookie'])
          router.push('/admin/apps')
          loading.value = false;
      }).catch((error) => {
          console.error(error)
          loading.value = false;
      });
      console.log('Success:', values);
      return false;
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      loading,
      onFinish,
      onFinishFailed,
    };
  },

});
</script>


